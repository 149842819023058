.tabs-inner {
    margin-top: 55px !important;
}

ion-item {
    padding: 0.5rem !important;
}

ion-label,
ion-note,
ion-text {
    padding: 0rem;
}

ion-col {
    border: solid 1px rgba(190, 190, 190, 0.411) !important;
}

html[mode="ios"] ion-router-outlet {
    margin: 55px auto auto auto;
    max-width: 900px;
}

ion-router-outlet {
    max-width: 1024px;
    margin: auto;
}

@media screen and (min-width: 1400px) {
    ion-toolbar {
        padding: 0px 250px 0px 250px
    }
}